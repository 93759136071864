import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

// Connects to data-controller="degrade"
export default class extends Controller {
  static targets = [ "spinner", "play", "video", "canvas", "audio" ]

  connect() {
    this.canvasContext = this.canvasTarget.getContext("2d");
    this.noise = 0;
    this.videoTarget.hidden = true;
    this.subscription = consumer.subscriptions.create(
      {
        channel: "DegradeChannel"
      },
      {
        received: () => this.increaseNoise(),
      }
    );
    setTimeout(() => this.checkPlayButton(), 2000)
  }

  increaseNoise() {
    console.log('[WS +1]')
    this.noise += 1;
  }

  checkPlayButton() {
    this.spinnerTarget.classList.add('d-none');
    this.playTarget.classList.remove('d-none');
  }

  playVideo() {
    this.playTarget.classList.add('d-none');
    this.resizeCanvas();
    this.videoTarget.play();
    this.audioTarget.play();
    requestAnimationFrame(() => this.updateFrame());
  }

  resizeCanvas() {
    this.canvasTarget.width = window.innerWidth;
    this.canvasTarget.height = window.innerHeight;
  }

  updateFrame() {
    const vidWidth = 1920;
    const vidHeight = 1080;
    const vidRatio = vidWidth / vidHeight;
    const winWidth = this.canvasTarget.width;
    const winHeight = this.canvasTarget.height;

    const realWidth = winHeight * vidRatio;

    const deltaWidth = (winWidth - realWidth) / 2;

    this.canvasContext.drawImage(this.videoTarget, 0, 0, vidWidth, vidHeight, deltaWidth, 0, realWidth, winHeight);

    const frame = this.canvasContext.getImageData(0, 0, realWidth, winHeight);
    const l = frame.data.length / 4;

    for (let i = 0; i < l; i++) {
      if (1 > 1000 * Math.random()) {
        const sliceLength = Math.round(Math.random() * (l - i) / 2 ** Math.max(1, 10 - this.noise));
        const sliceStart = i + Math.round(Math.random() * (l - sliceLength));

        for (let d = 0; d < sliceLength; d++) {
          [frame.data[(i + d) * 4], frame.data[(sliceStart + d) * 4]] = [frame.data[(sliceStart + d) * 4], frame.data[(i + d) * 4]];
          [frame.data[(i + d) * 4 + 1], frame.data[(sliceStart + d) * 4 + 1]] = [frame.data[(sliceStart + d) * 4 + 1], frame.data[(i + d) * 4 + 1]];
          [frame.data[(i + d) * 4 + 2], frame.data[(sliceStart + d) * 4 + 2]] = [frame.data[(sliceStart + d) * 4 + 2], frame.data[(i + d) * 4 + 2]];
        }

        break;
      }
    }

    this.canvasContext.putImageData(frame, 0, 0);

    requestAnimationFrame(() => this.updateFrame());
  }
}
