import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import debug from "stimulus_reflex/javascript/debug";

const CLIPS = {
  'closed': 'https://media.ufoshock.moe/file/ufoshock-media/misc-sites/clips_push/closed.webm',
  'opening': 'https://media.ufoshock.moe/file/ufoshock-media/misc-sites/clips_push/opening.webm',
  'openStable': 'https://media.ufoshock.moe/file/ufoshock-media/misc-sites/clips_push/closed_stable.webm',
  'openUnstable': 'https://media.ufoshock.moe/file/ufoshock-media/misc-sites/clips_push/closed_unstable.webm',
  'closing': 'https://media.ufoshock.moe/file/ufoshock-media/misc-sites/clips_push/closing.webm'
}

// Connects to data-controller="push-eye"
export default class extends Controller {
  static targets = [ 'floatingContainer', 'videoClosed', 'videoOpening', 'play',
                              'videoOpenStable', 'videoOpenUnstable', 'videoClosing' ]

  connect() {
    this.cable = consumer.subscriptions.create("PushChannel", {
      received: this.pushReceived.bind(this)
    });
    this.activity = 3;
    this.counter = 0
  }

  decay() {
    if (this.activity > 1) {
      this.activity = this.activity / 1.01;
    }
    switch (this.state) {
      case "closed":
        if (this.activity > 5) {
          this.switchVideo('opening');
        }
        break;
      case 'openStable':
        if (this.activity > 10) {
          this.switchVideo('openUnstable');
        } else if (this.activity < 10) {
          this.switchVideo('closing');
        }
        break;
      case 'openUnstable':
        if (this.activity < 5) {
          this.switchVideo('closing');
        }
        break;
    }
    window.requestAnimationFrame(this.decay.bind(this));
  }

  updateEnd() {
    console.log('video ended')
    switch (this.state) {
      case "opening":
        if (this.activity < 10) {
          this.switchVideo('closing');
        } else {
          this.switchVideo('openStable');
        }
        break;
      case 'closing':
        if (this.activity > 5) {
          this.switchVideo('opening');
        } else {
          this.switchVideo('closed');
        }
        break;
    }
  }

  /** @return HTMLVideoElement */
  get currentVideo() {
    switch (this.state) {
      case 'closed':
        return this.videoClosedTarget;
      case 'opening':
        return this.videoOpeningTarget;
      case 'openStable':
        return this.videoOpenStableTarget;
      case 'openUnstable':
        return this.videoOpenUnstableTarget;
      case 'closing':
        return this.videoClosingTarget;
      default:
        return null;
    }
  }

  /** @return HTMLVideoElement[] */
  get allVideo() {
    return [this.videoClosedTarget, this.videoOpeningTarget, this.videoOpenStableTarget,
      this.videoOpenUnstableTarget, this.videoClosingTarget]
  }

  switchVideo(newState) {
    let oVideo = this.currentVideo;
    console.log('switching from', this.state, 'to', newState)
    this.state = newState;
    this.currentVideo.play().then(() => {
      this.currentVideo.style.zIndex = (this.counter++).toString();
      if (oVideo !== null) {
        oVideo.currentTime = 0;
      }
    });
  }

  pushReceived(args) {
    this.activity++;
  }

  toggleMaximized() {
    this.allVideo.forEach((v) => v.classList.toggle('maximized'));
  }

  toggleFullscreen() {
    this.floatingContainerTarget.classList.toggle('fullscreen');
  }

  play() {
    this.playTarget.remove();
    this.switchVideo('closed');
    [this.videoOpeningTarget, this.videoClosingTarget].forEach((v) => {
      v.addEventListener('ended', this.updateEnd.bind(this));
    })
    window.requestAnimationFrame(this.decay.bind(this));
  }

  toggleBackground() {
    this.element.classList.toggle('black-background');
  }
}
