import { Controller } from "@hotwired/stimulus";
import ApexCharts from 'apexcharts';

// Connects to data-controller="heatmap"
export default class extends Controller {
  connect() {
    this.stats = JSON.parse(this.element.dataset.stats);
    this.title = this.element.dataset.title;
    this.max = 0;
    this.min = 0;
    this.mean = 0;

    const series = this.weekdays.map((d) => {
      return { name: d, data: [] }
    })

    for (let [key, value] of Object.entries(this.stats)) {
      value = parseFloat(value) || 0;
      let [day, hour] = key.split(',').map((k) => parseInt(k.replace(/[\[\]]/, '')));
      if (this.max < value) {
        this.max = value;
      } else if (this.min > value) {
        this.min = value;
      }
      this.mean += value;
      series[this.weekdays.length - day - 1].data.push([`${hour}:00`.padStart(5, '0'), value]);
    }

    this.mean /= Object.keys(this.stats).length;
    this.max = Math.ceil(this.max);

    const options = {
      series,
      chart: {
        height: 370,
        type: 'heatmap',
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 10,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [
            {
              from: this.min,
              to: (this.min + this.mean) / 2,
              name: 'low',
              color: '#00A100'
            },
            {
              from: (this.min + this.mean) / 2,
              to: this.mean,
              name: 'medium',
              color: '#128FD9'
            },
            {
              from: this.mean,
              to: (this.mean + this.max) / 2,
              name: 'high',
              color: '#FFB200'
            },
            {
              from: (this.mean + this.max) / 2,
              to: this.max,
              name: 'max',
              color: '#FF0000'
            }
            ],
            min: this.min,
            max: this.max
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          return (Math.round(value * 100) / 100).toString()
        }
      },
      stroke: {
        width: 1
      },
      title: {
        text: this.title
      },
    }

    const chart = new ApexCharts(this.element, options);
    chart.render();
  }

  get weekdays() {
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reverse()
  }
}
