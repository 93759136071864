import { Controller } from "@hotwired/stimulus"

const TIMEOUT = 3000;

function fadeIn(element, duration = 500) {
  element.animate(
      [
        { opacity: 0 },
        { opacity: 1 }
      ],
      {
        duration: duration,
        fill: 'forwards'
      }
  );
}

function fadeOut(element, duration = 500) {
  element.animate(
      [
        { opacity: 1 },
        { opacity: 0 }
      ],
      {
        duration: duration,
        fill: 'forwards'
      }
  );
}

// Connects to data-controller="eye"
export default class extends Controller {
  static targets = ['image', 'layer', 'container'];

  connect() {
    this.currentLayer = 0;
    window.scrollTo(0,0);
    this.setLayers(0);
    this.timer = null;
    this.state = 'hidden'
  }

  scrollFraction() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    return Math.min(scrollTop / scrollHeight, 1);
  }

  calculateLayer(fraction) {
    return Math.floor(fraction * 11);
  }

  setLayer(index) {
    this.imageTargets.forEach((image) => {
      if (image.dataset.index >= index) {
        image.style.opacity = '100%';
        image.classList.remove('hidden');
      } else {
        image.classList.add('hidden');
      }
    });
  }

  setOpacity(index, opacity) {
    this.imageTargets.forEach((image) => {
      if (image.dataset.index == index) {
        image.style.opacity = `${opacity}%`;
      }
    })
  }

  setLayers(index) {
    this.layerTargets.forEach((layer) => {
      const lIndex = parseInt(layer.dataset.index);
      if (lIndex === index) {
        layer.classList.add('active');
        layer.classList.remove('inactive');
      } else if (lIndex < index) {
        layer.classList.remove('active');
        layer.classList.add('inactive');
      } else {
        layer.classList.remove('active');
        layer.classList.remove('inactive');
      }
    });
  }

  hideLayers() {
    if (this.state === 'displayed') {
      this.state = 'hidden';
      fadeOut(this.containerTarget);
    }
  }

  showLayers() {
    if (this.state === 'hidden') {
      this.state = 'displayed'
      fadeIn(this.containerTarget);
    }
  }

  toggleLayers() {
    if (this.state === 'displayed') {
      this.hideLayers();
    } else {
      this.showLayers();
    }
  }

  jump(event) {
    const layer = parseInt(event.target.dataset.index);
    const yTarget = (layer / 11) * (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    window.scrollTo(0, yTarget + 10);
    this.update();
  }

  update() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.showLayers();
    }
    this.timer = setTimeout(this.hideLayers.bind(this), TIMEOUT);
    const fraction = this.scrollFraction();
    let newLayer = this.calculateLayer(fraction);
    if (this.currentLayer !== newLayer) {
      this.setLayer(newLayer);
      this.currentLayer = newLayer;
      this.setLayers(newLayer);
    }
    const base = newLayer * (1/11);
    const partialFraction = 11 * (fraction - base);
    if (partialFraction > 0.5) {
      this.setOpacity(newLayer, (1 - 2 * (partialFraction - 0.5)) * 100);
    }
  }
}
