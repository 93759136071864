import { Controller } from "@hotwired/stimulus"
import { Application, BitmapFont, BitmapText, TEXT_GRADIENT } from "pixi.js";
import { Emitter } from "@pixi/particle-emitter";
import consumer from "../channels/consumer"
import * as bootstrap from "bootstrap"

import * as emitterConfig from './emitter_config'

// Generates unique identifier
function uuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function randomFont() {
  return '0xA000 ' + ["Monochrome", "Pixelated", "Dots", "Dots Mono", "Pixelated Mono"][Math.floor(5*Math.random())];
}

// Default text style
const GFS = {
  fontSizeBase: 7,
  fontSizeDeviation: 2,
  align: 'left',
  fill: 0,
  factor: 0,
  stroke: 0xffffff,
  strokeThickness: 2
}

// Connects to data-controller="guestbook"
export default class extends Controller {
  static targets = [ "renderer", "form", "toasts" ]

  connect() {
    this.pool = [];
    this.activeText = [];
    this.elapsed = 0;
    this.activeFlag = true;

    this.initToasts();

    this.app = new Application({
      autoResize: true,
      resolution: devicePixelRatio
    });

    this.updateColors();
    this.rendererTarget.appendChild(this.app.view);
    this.resize();
    this.app.ticker.add(this.updateText, this);

    this.downloadNewText();

    const addTextFunc = this.addText.bind(this);

    consumer.subscriptions.create("GuestbookChannel", {
      received(data) {
        addTextFunc(data);
      }
    });
  }

  // Inits toasts using bootstrap
  initToasts() {
    const toastElList = [].slice.call(this.toastsTarget.querySelectorAll('.toast'));
    toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl)
    });
  }

  getToast(target) {
    return bootstrap.Toast.getOrCreateInstance(this.toastsTarget.querySelector(`#${target}`));
  }

  // Gets new text from pool, adds to the scene, and does it again in 3 to 5 seconds.
  // If pool is empty, skips iteration and gets new items.
  downloadNewText() {
    if (this.pool.length === 0) {
      this.startFetch();
    } else {
      if (this.activeFlag) {
        this.addText(this.pool.pop());
        this.activeFlag = false;
      }
    }
    setTimeout(this.downloadNewText.bind(this), 3000 + 2000 * Math.random());
  }

  // Gets new content from server
  startFetch() {
    fetch('/sites/guestbook/batch', { method: 'get' }).then((r) => r.json().then((r) => {
        this.pool.push(...r.entries);
      })
    )
  }

  buildStyle(message) {
    const fontName = uuid();
    const localGFS = {...GFS};
    switch (message.trait) {
      case 'dope':
        localGFS.fontSizeBase -= 4;
        const opFactor = Math.round(255 * (1 - this.dopeFraction(GFS.factor)));
        const strokeColor = Math.pow(256, 2) * opFactor + 256 * opFactor + opFactor;
        localGFS.stroke = strokeColor;
        break;
      case 'winner':
        localGFS.fontSizeBase += 2;
        localGFS.fill = 0xCCCC00;
        break;
      case 'cold':
        localGFS.fill = 0x66ffff;
        break;
      case 'hot':
        localGFS.fill = 0xff6600;
        break;
      case 'heaven':
        localGFS.fill = 0x6699ff;
        break;
      case 'hell':
        localGFS.fill = 0x800000;
        break;
      case 'outer':
        [localGFS.fill, localGFS.stroke] = [localGFS.stroke, localGFS.fill];
        break;
      case 'rainbow':
        const stops = []
        const rainbow = [0xff0000, 0xffa500, 0xffff00, 0x008000, 0x0000ff, 0x4b0082, 0xee82ee];
        let i = 0.0;
        rainbow.forEach((c) => {
          stops.push(i);
          i += 1.0 / (rainbow.length - 1);
        })
        localGFS.fill = rainbow;
        localGFS.fillGradientType = TEXT_GRADIENT.LINEAR_VERTICAL;
        localGFS.fillGradientStops = stops;
        break;
      case 'love':
        localGFS.fill = 0xff99ff;
        break;
      case 'spooky':
        localGFS.fill = 0xffcc66;
        break;
      case 'present':
        localGFS.fill = 0x009933;
        break;
      default:
        break;
    }
    BitmapFont.from(fontName, {
      ...localGFS,
      fontFamily: randomFont(),
      fontSize: 8 * (localGFS.fontSizeBase + Math.round((Math.random() - 0.5) * 2 * localGFS.fontSizeDeviation))
    }, { chars: BitmapFont.ASCII });
    return fontName;
  }

  // Adds new text object to scene
  addText(text) {
    this.updateColors();
    const newText = new BitmapText(text.message, {
      fontName: this.buildStyle(text)
    });
    this.app.stage.addChild(newText);
    newText.anchor.set(0, 0.5);

    const startPositionX = Math.floor(this.appParent.clientWidth * (1 + 0.1 * Math.random()))
    const startPositionY = Math.floor(Math.random() * this.appParent.clientHeight);

    newText.setTransform(startPositionX, startPositionY);

    let emitter = null;

    const activeObject = {
      width: newText.getLocalBounds().width,
      textObject: newText,
      emitter
    };

    if (emitterConfig.emittingTraits.includes(text.trait)) {
      emitterConfig.getConfig(text.trait).then((conf) => {
        emitter = new Emitter(this.app.stage, conf);
        emitter.updateOwnerPos(startPositionX, startPositionY);
        emitter.emit = true;
        emitter.update(0);
        activeObject.emitter = emitter;
      });
    }

    this.activeText.push(activeObject);
  }

  updateColors() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours() + currentTime.getMinutes() / 60.0;
    const factor = Math.round(255 * this.timeToFraction(currentHour));
    const opFactor = Math.round(255 * (1 - this.timeToFraction(currentHour)));
    const backgroundColor = Math.pow(256, 2) * factor + 256 * factor + factor;
    const strokeColor = Math.pow(256, 2) * opFactor + 256 * opFactor + opFactor;
    this.app.renderer.backgroundColor = backgroundColor;
    GFS.fill = backgroundColor;
    GFS.stroke = strokeColor;
    GFS.factor = this.timeToFraction(currentHour);

    const text = this.formTarget.querySelector("textarea");
    const submit = this.formTarget.querySelector("input:nth-child(3)");

    const colA = `rgb(${factor},${factor},${factor})`;
    const colB = `rgb(${opFactor},${opFactor},${opFactor})`;

    text.style.background = colA;
    text.style.color = `${colB}`;
    text.style.border = `${colB} solid`;
    submit.style.background = colA;
    submit.style.color = `${colB}`;
    submit.style.border = `${colB} solid`;
  }

  dopeFraction(factor) {
    if (factor <= 0.35) {
      return 0.35 + 0.15 * (factor / 0.35);
    } else {
      // factor >= 0.65
      return 0.5 + 0.15 * ((factor - 0.65) / 0.35);
    }
  }

  timeToFraction(hours) {
    if (hours < 12) {
      if (4.2 < hours && hours < 7.8) {
        return (hours > 6) ? 0.65 : 0.35;
      } else {
        return hours / 12.0;
      }
    } else {
      if (16.2 < hours && hours < 19.8) {
        return (hours < 18) ? 0.65 : 0.35;
      } else {
        return 1 - (hours - 12) / 12;
      }
    }
  }

  // Updates text animation
  updateText(delta) {
    this.activeFlag = true;
    this.elapsed += delta;
    const limit = this.activeText.length;
    for (let i = limit - 1 ; i >= 0 ; i--) {
      const target = this.activeText[i];
      if (target.textObject.x * -1 < target.width) {
        target.textObject.x -= delta * 3;
        if (target.emitter != null) {
          target.emitter.updateOwnerPos(target.textObject.x, target.textObject.y);
          target.emitter.update(delta * 0.01);
        }
      } else {
        target.textObject.destroy();
        this.activeText.splice(i, 1);
        if (target.emitter != null) {
          target.emitter.destroy();
        }
      }
    }
  }

  get appParent() {
    return this.app.view.parentNode;
  }

  // Resize the renderer
  resize() {
    this.app.renderer.resize(this.appParent.clientWidth, this.appParent.clientHeight);
  }

  // React to message just sent out
  messageSent(r) {
    this.formTarget.querySelector("textarea").value = '';
    r.detail.fetchResponse.response.json().then((j) => {
      const toastID = `${j.message.trait}TraitToast`;
      this.getToast(toastID).show();
    })
  }
}
