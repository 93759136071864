import { Controller } from "@hotwired/stimulus"

const TEXT = [
    "Come back in a few days",
    "Come back in a few hours",
    "Come back in a few minutes",
    "The time is now!"
]

const TARGET_TIME = new Date(Date.UTC(2024, 3, 23, 0));

// Connects to data-controller="s1m-landing-countdown"
export default class extends Controller {
    static targets = [ 'text', 'countdown' ]

  connect() {
        if (this.hasTextTarget){
            this.textTarget.style.opacity = 1;
        }
      if (this.hasCountdownTarget){
          this.countdownTarget.style.opacity = 1;
      }
  }

  render() {
        let delta = TARGET_TIME - (new Date(Date.now()));
        let newText = null;
        if (delta < 0) {
            newText = TEXT[3];
        }
        else if (delta < 60 * 60 * 1000) {
            newText = TEXT[2];
        } else if (delta < 24 * 60 * 60 * 1000) {
            newText = TEXT[1];
        } else {
            newText = TEXT[0];
        }
        if (this.hasTextTarget && newText !== this.textTarget.textContent) {
            this.textTarget.textContent = newText;
        }
        let newTime = null;
        const days = Math.floor(delta / (24 * 60 * 60 * 1000));
        delta = delta % (24 * 60 * 60 * 1000);
        const timer = new Date(Math.max(delta, 0)).toISOString().slice(11,19);
        newTime = String(days).padStart(2, '0') + ':' + timer;
        if (this.hasCountdownTarget && newTime !== this.countdownTarget.textContent) {
            this.countdownTarget.textContent = newTime;
        }
        if (delta < 0) {
            location.reload();
        } else {
            setTimeout(this.render.bind(this), 1000);
        }
  }
}
