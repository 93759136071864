import { upgradeConfig } from "@pixi/particle-emitter";
import { Texture } from "pixi.js";

export const emittingTraits = [
  "cold",
  "heaven",
  "hot",
  "outer",
  "rainbow",
  "winner",
  "hacker",
  "hell",
  "love",
  "present",
  "spooky"
];

export function getTexture(name) {
  if (['cold', 'hot', 'outer'].includes(name)) {
    name = 'default';
  }
  return Texture.from(`/sprites/${name}.png`);
}

export async function getConfig(name) {
  const config = await fetch(`/emitters/${name}.json`, { method: 'get' });
  const configJSON = await config.json();
  return upgradeConfig(configJSON, getTexture(name));
}