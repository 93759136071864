import { Controller } from "@hotwired/stimulus"

import { getStroke } from "perfect-freehand";

function getSvgPathFromStroke(stroke) {
  if (!stroke.length) return ""

  const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length]
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2)
        return acc
      },
      ["M", ...stroke[0], "Q"]
  )

  d.push("Z")
  return d.join(" ")
}

const options = {
  size: 9,
  thinning: 0.3,
  smoothing: 0.5,
  streamline: 0.5,
  easing: (t) => t,
  start: {
    taper: 0,
    easing: (t) => t,
    cap: true
  },
  end: {
    taper: 100,
    easing: (t) => t,
    cap: true
  }
};

// Connects to data-controller="s1m-confirmation-form"
export default class extends Controller {
  static targets = [ "open", "info", "submit", "signature", "signatureSVG" ]

  connect() {
    this.infoTarget.addEventListener('onsubmit', () => {
      return false;
    })
    this.points = [];
    this.saved = [];
  }

  handlePointerDown(e) {
    this.signatureSVGTarget.setPointerCapture(e.pointerId);
    this.points = [];
  }

  handlePointerMove(e) {
    if (e.buttons !== 1) return;
    const xMax = this.signatureSVGTarget.getBoundingClientRect().width;
    const yMax = this.signatureSVGTarget.getBoundingClientRect().height;
    this.points.push([780 * (e.offsetX / xMax), 180 * (e.offsetY / yMax), e.pressure]);
    this.update();
  }

  handlePointerUp(e) {
    this.update();
    this.saved.push(this.signatureSVGTarget.firstChild)
  }

  update() {
    while (this.signatureSVGTarget.firstChild) {
      this.signatureSVGTarget.removeChild(this.signatureSVGTarget.lastChild)
    }
    if (this.points) {
      const stroke = getStroke(this.points, options);
      const pathData = getSvgPathFromStroke(stroke);
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      path.setAttribute('d', pathData);
      this.signatureSVGTarget.appendChild(path);
      this.saved.forEach((s) => this.signatureSVGTarget.appendChild(s))
    }
  }

  send() {
    const s = new XMLSerializer();
    this.signatureTarget.value = s.serializeToString(this.signatureSVGTarget);
    this.openTarget.textContent = "Confirmed! Let's do our best!!"
    this.openTarget.dataset.bsToggle = '';
    this.infoTarget.requestSubmit();
  }
}
