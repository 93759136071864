import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="s1m-handbook-token"
export default class extends Controller {
  static targets = ["field"]

  connect() {
    navigator.clipboard.writeText(this.data.get('prepend') + window.location.host + this.data.get('target') + this.fieldTarget.value);
  }
}
