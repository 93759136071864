import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="s1m-submission-form"
export default class extends Controller {

  static targets = ["stage0", "stage1", "stage2", "stage3", "stage4", "challenge"];

  connect() {
    this.answers = {};
    this.valid = false;
    this.payload = null;
    this.challengeTarget.addEventListener('verified', (ev) => {
      this.valid = true;
      this.payload = ev.detail.payload;
    });
  }

  stage1() {
    this.stage0Target.classList.toggle('hidden');
    this.stage1Target.classList.toggle('hidden');
  }

  stage2() {
    this.advance(this.stage1Target, this.stage2Target);
  }

  stage3() {
    this.advance(this.stage2Target, this.stage3Target);
  }

  stage4() {
    if(this.validate(this.stage3Target) && this.valid) {
      const csrfToken = document.querySelector("[name='csrf-token']").content
      const submissionPayload = {
        submission: this.answers,
        altcha: this.payload
      };
      fetch(window.location, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
          "Content-Type": "application/json"
        },
        body: JSON.stringify(submissionPayload)
      }).then(() => this.advance(this.stage3Target, this.stage4Target));
    }
  }

  validate(current) {
    let correct = true;
    current.querySelectorAll('div.input-group > *').forEach((v) => {
      if(v.hasAttribute('required') && v.value === '') {
        correct = false;
        return;
      }
      this.answers[v.id] = v.value;
    });
    return correct;
  }

  advance(current, next) {
    if(this.validate(current)) {
      current.classList.toggle('hidden');
      next.classList.toggle('hidden');
    }
  }
}
