import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="s1m-form"
export default class extends Controller {
  static targets = [ "open", "info", "submit", "challenge" ]

  connect() {
    this.challengeTarget.addEventListener('verified', () => {
      this.submitTarget.disabled = false;
    });
    this.infoTarget.addEventListener('onsubmit', () => {
      return false;
    })
  }
  send() {
    this.infoTarget.requestSubmit();
    this.openTarget.textContent = "Application sent, I'll contact you soon!"
    this.openTarget.disabled = true;
    return false;
  }
}
