import { Controller } from "@hotwired/stimulus"
import * as THREE from 'three';

import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import {DRACOLoader} from "three/addons";

import WebGL from 'three/addons/capabilities/WebGL.js';




const TARGET_TIME = new Date(Date.UTC(2024, 2, 26, 22));

const randomFont = () => {
  return ["0xA000 Monochrome", "0xA000 Pixelated Mono", "0xA000 Dots Mono"][Math.floor(Math.random() * 3)];
}

// Connects to data-controller="s1m-landing"
export default class extends Controller {
  static targets = [ 'countdown', 'contributors', 'webglMobile', 'webglDesktop', 'freeDownload' ]

  static values = {
    videourl: {
      type: String,
      default: 'https://media.ufoshock.moe/file/ufoshock-media/s1m/codec_dashinit.mp4'
    },
    freeurl: {
      type: String,
      default: 'https://media.ufoshock.moe/file/ufoshock-media/s1m/free-edition/S1M_ISSUE_I_FREE_EDITION.pdf'
    },
    filename: {
      type: String,
      default: 'S1M_ISSUE_I_FREE_EDITION.pdf'
    },
    issuepath: {
      type: String,
      default: '/issueI/free-edition'
    },
    modelfile: {
      type: String,
      default: 'mockup6.glb'
    }
  }

  connect() {
    this.assignUnknown();
    this.updateTime();
    this.clickedFreeEdition = false;
    if ( WebGL.isWebGLAvailable() )
    {
      this.camera = this.scene = this.renderer = this.mixer = this.bookModel = null;
      this.rotateCurrent = 0;
      this.rotateMax = Math.PI / 4;
      this.mouseY = 0;
      this.clock = new THREE.Clock();
      this.loadModel();
    } else
    {
      this.videoPlaying = false;
      this.video = document.createElement('video');
      this.video.style.height = '100%';
      this.video.style.width = '100%';
      this.video.setAttribute('autoplay', '');
      this.video.setAttribute('mute', '');
      this.video.setAttribute('loop', '');
      const source = this.videourlValue;
      const type = 'video/mp4';
      const sourceTag = document.createElement('source');
      sourceTag.setAttribute('src', source);
      sourceTag.setAttribute('type', type);
      this.video.append(sourceTag);
      this.addVideo();
      window.addEventListener( 'resize', this.addVideo.bind(this) );
      window.addEventListener('mousedown', this.playVideo.bind(this));
      window.addEventListener('touchstart', this.playVideo.bind(this));
    }
  }

  freeDownloadAction() {
    if(this.clickedFreeEdition) {
      return;
    }
    this.clickedFreeEdition = true;
    this.freeDownloadTarget.innerText = 'READING THE FREE EDITION';
    const downloadLink = this.freeurlValue;
    fetch(this.issuepathValue, { 'mode': 'no-cors' }).then(() => {
      fetch(downloadLink)
          .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = this.filenameValue;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
    })
  }

  playVideo() {
    if (!this.videoPlaying) {
      this.video.play().then(r => this.videoPlaying = true);
    }
  }

  addVideo() {
    this.model.append(this.video);
    this.video.play().then(r => this.videoPlaying = true);
    this.emptyModel.textContent = '';
  }

  get is_mobile() {
    return window.innerHeight > window.innerWidth;
  }

  get model() {
    return this.is_mobile ? this.webglMobileTarget : this.webglDesktopTarget;
  }

  get emptyModel() {
    return !this.is_mobile ? this.webglMobileTarget : this.webglDesktopTarget;
  }

  handleMouse(e) {
    this.mouseY = (e.pageY / window.innerHeight) - 0.5;
  }

  loadModel() {
    this.camera = new THREE.PerspectiveCamera( 50, this.model.offsetWidth / this.model.offsetHeight, 0.25, 20 );
    this.camera.position.set( 4.4, 0.0, 0.0 );
    this.camera.lookAt(0, 0, 0)

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer( { antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.model.offsetWidth, this.model.offsetHeight );
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.model.appendChild( this.renderer.domElement );

    window.addEventListener( 'resize', this.onWindowResize.bind(this) );
    window.addEventListener( 'mousemove', this.handleMouse.bind(this) );

    const loader = new GLTFLoader().setPath( 'https://media.ufoshock.moe/file/ufoshock-media/s1m/' );

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader( dracoLoader );

    loader.load( this.modelfileValue, async ( gltf ) => {

      this.bookModel = gltf.scene;

      // wait until the model can be added to the scene without blocking due to shader compilation

      await this.renderer.compileAsync( this.bookModel, this.camera, this.scene );

      const ambientLight = new THREE.AmbientLight(0xffffff, 3);
      this.scene.add(ambientLight);
      this.scene.add( this.bookModel );

      this.mixer = new THREE.AnimationMixer( this.bookModel );

      gltf.animations.forEach( ( clip ) => {

        this.mixer.clipAction( clip ).play();

      } );

      this.animate();

    } );
  }

  animate() {

    const delta = this.clock.getDelta();

    if ( this.mixer ) this.mixer.update( delta );
    const move = -1 * delta * this.mouseY;

    if (Math.abs(this.rotateCurrent + move) < Math.abs(this.rotateMax)) {
      this.bookModel.rotateZ(move);
      this.rotateCurrent += move;
    }

    this.renderer.render( this.scene, this.camera );

    requestAnimationFrame( this.animate.bind(this) );

  }

  onWindowResize() {
    this.camera.updateProjectionMatrix();

    this.camera.aspect = this.model.offsetWidth / this.model.offsetHeight;

    this.renderer.setSize( this.model.offsetWidth, this.model.offsetHeight );

    this.camera.updateProjectionMatrix();

    this.model.appendChild( this.renderer.domElement );

    this.render();
  }

  render() {
    this.renderer.render( this.scene, this.camera );
  }

  assignUnknown() {
    if (!this.hasContributorsTarget) {
      return;
    }
    this.contributorsTarget.querySelectorAll(':scope span').forEach((c) => {
      c.setAttribute('style', `font-family: "${randomFont()}";`);
    });
  }

  updateTime() {
    if (!this.hasCountdownTarget) {
      return;
    }
    let newTime;
    let delta = TARGET_TIME - (new Date(Date.now()));
    const days = Math.floor(delta / (24 * 60 * 60 * 1000));
    delta = delta % (24 * 60 * 60 * 1000);
    let timer = new Date(delta).toISOString().slice(11,19);
    newTime = String(days).padStart(2, '0') + ':' + timer;
    if (newTime !== this.countdownTarget.textContent) {
      this.countdownTarget.textContent = newTime;
    }
    if (delta < 0) {
      location.reload();
    } else {
      setTimeout(this.updateTime.bind(this), 1000);
    }
  }
}