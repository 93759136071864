import { Controller } from "@hotwired/stimulus"

import {WebGLRenderer, Scene, OrthographicCamera, Clock, PlaneGeometry, Vector2, ShaderMaterial, Mesh} from "three"

// Connects to data-controller="plain-background"
export default class extends Controller {
  connect() {
    this.valid = false;
    if (document.getElementById("vertexShader") === null ||
        document.getElementById("fragmentShader") === null) {
      return;
    }
    this.valid = true;
    this.renderer = new WebGLRenderer();
    this.scene = new Scene();
    this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
    this.clock = new Clock(true);

    this.uniforms = {
      u_time : {
        type : "f",
        value : 0.0
      },
      f_time : {
        type: "f",
        value: 0.0
      },
      u_frame : {
        type : "f",
        value : 0.0
      },
      bit_size : {
        type : "f",
        value: 6.0
      },
      seed : {
        type : "f",
        value : 1000.0 * Math.random()
      },
      shift : {
        type : "f",
        value : Math.fround(Math.round(10 * Math.random()))
      },
      u_resolution : {
        type : "v2",
        value : new Vector2(window.innerWidth, window.innerHeight)
            .multiplyScalar(window.devicePixelRatio)
      },
      u_mouse : {
        type : "v2",
        value : new Vector2(0.7 * window.innerWidth, window.innerHeight)
            .multiplyScalar(window.devicePixelRatio)
      }
    };

    this.init();
    this.animate();
  }

  init() {
    // Initialize the WebGL renderer
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.element.appendChild(this.renderer.domElement);

    this.element.lastChild.classList.add('fullscreen');
    this.element.lastChild.classList.add('background');

    const geometry = new PlaneGeometry(2, 2);

    // Create the shader material
    const material = new ShaderMaterial({
      uniforms : this.uniforms,
      vertexShader : document.getElementById("vertexShader").textContent,
      fragmentShader : document.getElementById("fragmentShader").textContent
    });

    // Create the mesh and add it to the scene
    const mesh = new Mesh(geometry, material);
    this.scene.add(mesh);
  }

  /*
   * Animates the sketch
   */
  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.render();
  }

  /*
   * Renders the sketch
   */
  render() {
    this.uniforms.u_time.value = Math.floor(this.clock.getElapsedTime());
    this.uniforms.f_time.value = this.clock.getElapsedTime() * 0.4;
    this.uniforms.u_frame.value += 1.0;
    this.renderer.render(this.scene, this.camera);
  }

  /*
   * Updates the renderer size and the uniforms when the window is resized
   */
  onWindowResize(event) {
    if (!this.valid) {
      return;
    }
    // Update the renderer
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    // Update the resolution uniform
    this.uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight).multiplyScalar(window.devicePixelRatio);
  }

  /*
   * Updates the uniforms when the mouse moves
   */
  onMouseMove(event) {
    if (!this.valid) {
      return;
    }
    // Update the mouse uniform
    this.uniforms.u_mouse.value.set(event.pageX, window.innerHeight - event.pageY).multiplyScalar(
        window.devicePixelRatio);
  }

  /*
   * Updates the uniforms when the touch moves
   */
  onTouchMove(event) {
    if (!this.valid) {
      return;
    }
    event.preventDefault();

    // Update the mouse uniform
    this.uniforms.u_mouse.value.set(event.touches[0].pageX, window.innerHeight - event.touches[0].pageY).multiplyScalar(
        window.devicePixelRatio);
  }
}
