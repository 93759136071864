import { Controller } from "@hotwired/stimulus"

import { csv } from "d3-fetch"

// Connects to data-controller="depression"
export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    const TIMEOUT = 4000;

    const DATASET = csv('/depression.csv');

    DATASET.then((data) => {
      const MASTER = [...data];
      setInterval(() => {
        if (data.length === 0) {
          data.push(...MASTER)
        }
        const newTweet = this.createTweetHTML(this.getTweetText(data));
        this.containerTarget.prepend(newTweet);
        const fontSize = parseFloat(getComputedStyle(newTweet).fontSize.replace('px', ''));
        const avalWidth = parseFloat(getComputedStyle(newTweet).width.replace('px', ''));
        const lineCount = 1 + Math.ceil((0.6 * fontSize * newTweet.textContent.length) / avalWidth);
        newTweet.style.transition = `max-height ${0.6 * lineCount}s ease-out`;
        newTweet.style.maxHeight = `${24 + 1.5 * fontSize * lineCount}px`;
      }, TIMEOUT);
    })
  }

  getTweetText(data) {
    const index = Math.floor(Math.random() * data.length);
    const value = data[index]['TWEET_TEXT'].replaceAll('EXTERNAL_LINK', '');
    data.splice(index, 1);
    return value;
  }

  createTweetHTML(content) {
    const node = document.createElement('div');
    node.classList.add('tweet-box');
    node.appendChild(document.createTextNode(content));
    return node;
  }
}
