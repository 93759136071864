import { Controller } from "@hotwired/stimulus"

import { Tooltip } from "bootstrap";

// Connects to data-controller="zine-notice2"
export default class extends Controller {
  static targets = [ 'chilePrice', 'survey', 'notice' ];

  connect() {
    new Tooltip(this.chilePriceTarget);
    const urlParams = new URLSearchParams(window.location.search);
    const survey = urlParams.get('survey');
    if (survey === 'done') {
      this.surveyTarget.remove();
      this.noticeTarget.textContent = 'Thanks for answering!';
      this.noticeTarget.scrollIntoView();
    }
  }
}
