import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinning-head"

const FRAMES = [
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346530/head/01_ghs2l0.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346530/head/02_pl4v9s.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346530/head/03_cadygg.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346531/head/04_gui4vh.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346528/head/05_jvmx2s.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346530/head/06_tyxg9e.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346531/head/07_rn9qyp.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346532/head/08_qsbgwf.png",
    "https://res.cloudinary.com/ufoshock-bucket/image/upload/v1682346532/head/09_lhsvnl.png"
]

const EMPTY_BAR = ['', '', ''];

const FULL_BAR = ['', '', ''];

const BAR_LENGTH = 40;

const DELAY = 150;

export default class extends Controller {
    static targets = [ "loadingBar", "canvas", "site", "overlay" ]

    connect() {
        this.progress = 0;
        this.stop = false;
        this.pool = FRAMES.map((v) => {
            const image = new Image();
            image.src = v;
            return image;
        })
        this.resize();
        this.start = Date.now();
        this.targetPoint = null;
        this.siteTargets.forEach((st) => {
            st.addEventListener("mouseover", this.hoverIn.bind(this));
            st.addEventListener("mouseleave", this.hoverOut.bind(this));
        });
        requestAnimationFrame(() => this.updateBar());
    }

    updateBar(_delta) {
        let count = this.pool.reduce((p, c) => p + (c.complete ? 1 : 0), 0);
        let since_start = Date.now() - this.start;
        let too_fast = (count === FRAMES.length) && (since_start < 2000);
        if (too_fast) {
            count = Math.ceil(FRAMES.length * (since_start / 2000));
            if (count < this.progress) {
                count = this.progress;
            }
        }
        if (count > this.progress) {
            let advance = Math.ceil(BAR_LENGTH * (count / FRAMES.length));
            let text = FULL_BAR[0];
            text = text + FULL_BAR[1].repeat(advance)
            text = text + EMPTY_BAR[1].repeat(BAR_LENGTH - advance)
            text = text + ((advance !== BAR_LENGTH) ? EMPTY_BAR[2] : FULL_BAR[2])
            this.loadingBarTarget.innerText = text;
        }
        this.progress = count;
        if (this.progress < FRAMES.length) {
            requestAnimationFrame(() => this.updateBar());
        } else {
            this.ctx = this.canvasTarget.getContext("2d");
            setTimeout(() => this.startAnimation(), 500)
        }
    }

    startAnimation() {
        this.loadingBarTarget.classList.add('transparent');
        this.frame = 0;
        this.changeFrame();
    }

    changeFrame() {
        if (this.stop) {
            return setTimeout(() => this.changeFrame(), DELAY);
        }

        const img = this.pool[this.frame];

        const scaleFactor = Math.min(this.canvasTarget.width / img.width, this.canvasTarget.height / img.height);

        const canvasCenterX = this.canvasTarget.width / 2;
        const canvasCenterY = this.canvasTarget.height / 2;

        const imgWidth = img.width * scaleFactor;
        const imgHeight = img.height * scaleFactor;
        const imgCenterX = imgWidth / 2;
        const imgCenterY = imgHeight / 2;

        this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

        this.ctx.drawImage(img, canvasCenterX - imgCenterX, canvasCenterY - imgCenterY, imgWidth, imgHeight);

        const rect = this.canvasTarget.getBoundingClientRect();
        this.targetPoint = {
            x: rect.left + (canvasCenterX - imgCenterX) + Math.round((0.3 + 0.4 * Math.random()) * imgWidth),
            y: rect.top + (canvasCenterY - imgCenterY) + Math.round((0.2 + 0.6 * Math.random()) * imgHeight)
        };

        this.frame += 1;

        if (this.frame === this.pool.length) {
            this.frame = 0;
        }

        setTimeout(() => this.changeFrame(), DELAY)
    }

    resize() {
        this._resize(this.canvasTarget);
        this._resize(this.overlayTarget);
    }

    _resize(target) {
        const style = getComputedStyle(target);
        const width = parseInt(style.width);
        const height = parseInt(style.height);
        target.width = width;
        target.height = height;
    }

    hoverIn(e) {
        const ctx = this.overlayTarget.getContext('2d')
        ctx.clearRect(0, 0, this.overlayTarget.width, this.overlayTarget.height);
        if (e.target.classList.contains("site-title") && this.targetPoint !== null) {
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const initPoint = { x: rect.left - 7, y: rect.top + Math.round(rect.height / 2) };
            const targetPoint = this.targetPoint;
            const isLeft = e.target.classList.contains("triptych-left")
            if (isLeft) {
                initPoint.x += rect.width + 14;
            }

            const lineWidth = parseFloat(window.getComputedStyle(target.parentNode).getPropertyValue("border-width"));

            ctx.beginPath();
            ctx.strokeStyle = "white";
            ctx.lineWidth = lineWidth;
            ctx.moveTo(initPoint.x, initPoint.y);
            ctx.lineTo(Math.round((initPoint.x + targetPoint.x) / 2), initPoint.y);
            ctx.lineTo(Math.round((initPoint.x + targetPoint.x) / 2), targetPoint.y);
            ctx.lineTo(targetPoint.x + (isLeft ? -1 : 1) * lineWidth * 15, targetPoint.y);
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = "white";
            ctx.lineWidth = lineWidth;
            ctx.arc(targetPoint.x, targetPoint.y, lineWidth * 15, 0, 2 * Math.PI);
            ctx.stroke();

            this.stop = true;
        }
    }

    hoverOut(_e) {
        const ctx = this.overlayTarget.getContext('2d')
        ctx.clearRect(0, 0, this.overlayTarget.width, this.overlayTarget.height);
        this.stop = false;
    }
}