import { Controller } from "@hotwired/stimulus"

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

// Connects to data-controller="token-field"
export default class extends Controller {
  static targets = [ 'field' ]

  randomize() {
    this.fieldTarget.value = uuidv4();
    this.copy();
  }

  copy() {
    const url = 'https://' + this.data.get('prepend') + window.location.host + this.data.get('target') + this.fieldTarget.value;
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(url);
    }
    console.log(url)
  }
}
