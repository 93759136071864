import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ['calendarElement']

  connect() {
    this.calendar = jsCalendar.new(this.calendarElementTarget);
    const rid = this.reallyImportantDates();
    const id = this.importantDates();
    this.calendar.onDateRender(function(date, element, _i) {
      rid.forEach((rid_element) => {
        let [rmonth, rday] = rid_element;
        if (date.getDate() === rday && date.getMonth() === rmonth) {
          element.style.fontSize = '22px';
          element.style.color = '#c32525';
        }
      });
      id.forEach((rid_element) => {
        let [rmonth, rday] = rid_element;
        if (date.getDate() === rday && date.getMonth() === rmonth) {
          element.style.fontSize = '18px';
          element.style.color = '#FFFFE0';
        }
      });
    });
    this.calendar.refresh();
  }

  reallyImportantDates() {
    return [
      [7, 1],
      [7, 12],
      [8, 2]
    ];
  }

  importantDates() {
    return [
      [5, 28],
      [6, 8],
      [7, 19],
      [7, 26],
      [8, 16],
      [8, 30]
    ]
  }
}
