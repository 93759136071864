import PhotoSwipe from "photoswipe";
import {Controller} from "@hotwired/stimulus"
import PhotoSwipeLightbox from "photoswipe/lightbox";
import ahoy from "ahoy.js";

export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallerySelector: '#gallery-container',
      // Elements within gallerySelector (slides)
      childSelector: 'a',
      pswpModule: PhotoSwipe
    });

    lightbox.on('uiRegister', function () {
      lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (el, pswp) => {
          lightbox.pswp.on('change', () => {
            const currSlideElement = lightbox.pswp.currSlide.data.element;
            let captionHTML = '';
            if (currSlideElement) {
              // get caption from element with class hidden-caption-content
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
            el.innerHTML = captionHTML || '';
          });
        }
      });
    });

    lightbox.on('change', () => {
      ahoy.track('Opened Slide', { entryID: lightbox.pswp.currSlide.data.element.dataset.entryId })
    });

    lightbox.init();
  }
}
