import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown"
export default class extends Controller {
  static values = {
    deadline: String,
    refresh: Boolean
  }
  connect() {
    this.deadlineDate = Date.parse(this.deadlineValue);
    this.element.classList.add('countdown');
    this.render();
  }

  get delta() {
    return this.deadlineDate - (new Date(Date.now()));
  }

  updateTime(delta) {
    const days = Math.floor(delta / (24 * 60 * 60 * 1000));
    const ms = delta % (24 * 60 * 60 * 1000)
    const timer = new Date(Math.max(ms, 0)).toISOString().slice(11,19);
    const newTime = String(days).padStart(2, '0') + ':' + timer;
    if (newTime !== this.element.textContent) {
      this.element.textContent = newTime;
    }
  }

  render() {
    if (this.delta < 0) {
      this.updateTime(0);
      if (this.refreshValue) {
        location.reload();
      }
      return;
    }
    this.updateTime(this.delta);

    setTimeout(this.render.bind(this), 1000);
  }
}
