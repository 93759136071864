import { Controller } from "@hotwired/stimulus"

const randomFont = () => {
  return ["0xA000 Monochrome", "0xA000 Pixelated Mono", "0xA000 Dots Mono"][Math.floor(Math.random() * 3)];
}

// Connects to data-controller="contributor-list"
export default class extends Controller {
  connect() {
    this.assignUnknown();
    this.element.classList.add('s1m-contributor-container');
  }

  assignUnknown() {
    this.element.querySelectorAll(':scope span').forEach((c) => {
      c.setAttribute('style', `font-family: "${randomFont()}";`);
    });
  }
}
