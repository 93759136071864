import { Controller } from "@hotwired/stimulus"
import {WebGLRenderer, Scene, OrthographicCamera, Clock, PlaneGeometry, Vector2, ShaderMaterial, Mesh} from "three"
import consumer from "../channels/consumer"


// Connects to data-controller="push"
export default class extends Controller {
  static targets = ['fallback', 'canvas']

  connect() {
    this.cable = consumer.subscriptions.create("PushChannel", {
      received: this.pushReceived.bind(this)
    });
    if (! document.createElement("canvas").getContext('webgl')) {
      this.fallbackTarget.classList.add('enabled');
    } else {
      this.valid = true;
      this.intensity = 0;
      this.renderer = new WebGLRenderer({canvas: this.canvasTarget});
      this.scene = new Scene();
      this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
      this.clock = new Clock(true);
      this.uniforms = {
        uTime : {
          type : "f",
          value : 0.0
        },
        uIntensity : {
          type: "f",
          value: 0.0
        },
        uResolution : {
          type : "v2",
          value : new Vector2(Math.min(window.innerWidth, window.innerHeight),
                              Math.min(window.innerWidth, window.innerHeight))
        },
      };
      this.init();
      this.animate();
    }
  }

  /*
   * Animates the sketch
   */
  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.intensity = this.intensity / 1.05;
    this.render();
  }

  /*
   * Renders the sketch
   */
  render() {
    this.uniforms.uTime.value = this.clock.getElapsedTime();
    this.uniforms.uIntensity.value = this.intensity;
    this.renderer.render(this.scene, this.camera);
  }

  init() {
    // Initialize the WebGL renderer
    this.renderer.setSize(Math.min(window.innerWidth, window.innerHeight),
                          Math.min(window.innerWidth, window.innerHeight));

    const geometry = new PlaneGeometry(2, 2);

    // Create the shader material
    const material = new ShaderMaterial({
      uniforms : this.uniforms,
      vertexShader : document.getElementById("vertexShader").textContent,
      fragmentShader : document.getElementById("fragmentShader").textContent
    });

    // Create the mesh and add it to the scene
    const mesh = new Mesh(geometry, material);
    this.scene.add(mesh);
    this.onWindowResize(null);
  }

  pushReceived(args) {
    console.log('touched', args)
    this.intensity++;
  }

  sendPush() {
    this.cable.send({dummy: 1});
  }

  onWindowResize(event) {
    if (!this.valid) {
      return;
    }
    // Update the renderer
    this.renderer.setSize(Math.min(window.innerWidth, window.innerHeight),
                          Math.min(window.innerWidth, window.innerHeight));

    // Update the resolution uniform
    this.uniforms.uResolution.value.set(Math.min(window.innerWidth, window.innerHeight),
                                        Math.min(window.innerWidth, window.innerHeight));
  }
}
