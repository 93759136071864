import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="about"
export default class extends Controller {
  static targets = ['nameCode']

  connect() {
    this.startTimeout = null;
    this.spawnTimeout = null;
  }

  flying() {
    this.clear();
    this.startTimeout = setTimeout(this.addFlying.bind(this), 1000);
    this.spawnTimeout = setTimeout(this.spawnName.bind(this), 5000);
  }

  clear() {
    if (this.spawnTimeout !== null) {
      window.clearTimeout(this.startTimeout);
      window.clearTimeout(this.spawnTimeout);
      this.nameCodeTarget.classList.remove('fly-container')
      this.startTimeout = null;
      this.spawnTimeout = null;
    }
  }

  spawnName() {
    this.nameCodeTarget.innerHTML = 'Aisea Yuu';
  }

  addFlying() {
    this.nameCodeTarget.classList.add('fly-container')
  }
}
