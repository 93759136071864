import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="s1m-receipt-form"
export default class extends Controller {
  static targets = [ "form", "submit", "formStage", "confirm" ]

  connect() {
  }

  send() {
    if (this.formTarget.checkValidity()) {
      this.submitTarget.classList.toggle("hidden");
      this.formStageTarget.classList.toggle("hidden");
      this.confirmTarget.classList.toggle("hidden");
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.reportValidity();
    }
  }
}
